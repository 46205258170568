import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { QuickStartComponent } from "./components/quick-start/quick-start.component";
import { NgxEchartsModule } from "ngx-echarts";
import { MatIconModule } from "@angular/material/icon";
import { MatButtonModule } from "@angular/material/button";
import { MatExpansionModule } from "@angular/material/expansion";
import { SharedPipesModule } from "../../pipes/shared-pipes.module";
import { QuickStartDraggableComponent } from "./components/quick-start-draggable/quick-start-draggable.component";

@NgModule({
  declarations: [QuickStartComponent, QuickStartDraggableComponent],
  imports: [
    SharedPipesModule,
    CommonModule,
    NgxEchartsModule.forRoot({
      echarts: () => import("echarts"),
    }),
    MatIconModule,
    MatButtonModule,
    MatExpansionModule,
  ],
  exports: [QuickStartComponent, QuickStartDraggableComponent],
})
export class QuickStartModule {}
