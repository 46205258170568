<h2 mat-dialog-title data-testid="modal-title">Add Multi-Factor Authentication</h2>
<mat-dialog-content>
  <p>You do not have MFA set up on your account.</p>
  <p>
    We recommend adding multi-factor authentication to your account for security
    purposes.
  </p>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button (click)="decline2FA()">No Thanks</button>
  <button
    mat-raised-button
    (click)="navigate2FA()"
    color="primary"
    cdkFocusInitial
  >
    Set Up MFA
  </button>
</mat-dialog-actions>
