import { Injectable } from "@angular/core";
import { AbstractControl, ValidatorFn, Validators } from "@angular/forms";
import { emailRegex, usTerritories } from "../utils/constants";
import { TaxIdInputMaskRelationType, TaxIdPlaceholderRelationType } from "../interfaces/generic.interface";

@Injectable({
  providedIn: "root",
})
export class FormValidationService {
  public static taxIdValidator = (taxIdTypeControlName = "taxIdType"): ValidatorFn => {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      const formGroup = control.parent;
      if (!formGroup) return null;

      const taxIdType = formGroup.get(taxIdTypeControlName)?.value;
      const taxId = control.value;

      const numbers = taxId?.match(/\d/g);

      if (taxId && numbers?.length !== 9 && taxIdType !== "Foreign") {
        control.setErrors({ invalidLength: true });
        return { invalidLength: true };
      } else {
        control.setErrors(null);
        return null;
      }
    };
  };

  public static fincenIdValidator = (): ValidatorFn => {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      const formGroup = control.parent;
      if (!formGroup) return null;

      const fincenId = control.value;
      const numbers = fincenId?.match(/\d/g);

      if (fincenId && numbers.length !== 12) {
        control.setErrors({ invalid: true });
        return { invalid: true };
      } else {
        control.setErrors(null);
        return null;
      }
    };
  };

  public static emailValidator = (): ValidatorFn => {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      const email = control.value;

      if (email && !emailRegex.test(email)) {
        control.setErrors({ invalid: true });
        return { invalid: true };
      } else {
        control.setErrors(null);
        return null;
      }
    };
  };

  public static usPhoneValidator = (): ValidatorFn => {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      const numbers = control.value?.match(/\d/g);
      if (control.value && !numbers) return { invalid: true };
      if (control.value && numbers.length !== 10) {
        return { invalid: true };
      }
      return null;
    };
  };

  /**
   * Validator to ensure the input is not composed only of whitespace.
   */
  public static notOnlyWhitespaceValidator = (): ValidatorFn =>
    Validators.pattern(/^(?!\s+$).*$/);

  public static getEnabledControlValue<T>(control?: AbstractControl<T> | null): T | null {
    return control?.enabled ? (control?.value || null) : null;
  }
  
  public static readonly taxIdPlaceholderRelation: TaxIdPlaceholderRelationType = {
    "EIN": "12-3456789",
    "SSN/ITIN": "123-45-6789",
    "Foreign": "123456789",
    "None": "123456789",
  };    

  public static readonly taxIdInputMaskRelation: TaxIdInputMaskRelationType = {
    "EIN": { mask: "00-0000000" },
    "SSN/ITIN": { mask: "000-00-0000" },
    "Foreign": {
      mask: "[#########################]",
      definitions: {
        "#": /[0-9a-zA-Z]/,
      },
    },
    "None": {
      mask: "[#########################]",
      definitions: {
        "#": /[0-9a-zA-Z]/,
      },
    },
  };

  public zipCodeMask = (country?: string | null) => {
    if (
      country &&
      country !== "United States" &&
      !usTerritories.includes(country)
    ) {
      return {
        mask: "[#########]",
        definitions: {
          "#": /[0-9a-zA-Z]/,
        },
      };
    } else {
      return {
        mask: "[#########]",
        definitions: {
          "#": /\d/,
        },
      };
    }
  };

  public zipCodeLength = (country?: string | null): [number, number] => {
    if (
      country &&
      country !== "United States" &&
      !usTerritories.includes(country)
    ) {
      return [1, 9];
    } else {
      return [5, 9];
    }
  };

  public static phoneCodeMask = {
    mask: "[######]",
    definitions: {
      "#": /\d/,
    },
  };
}
