import { inject, Injectable } from '@angular/core';
import { doc, Firestore, getDoc } from '@angular/fire/firestore';

export interface IBotFeatureFlag {
  shouldUseBot: boolean;
}

export interface IFeatureFlags {
  bot: IBotFeatureFlag;
}

@Injectable({
  providedIn: 'root'
})
export class FeatureFlagsService {
  firestore = inject(Firestore);

  async shouldUseBot(): Promise<boolean> {
    const shouldUseBotDot = doc(this.firestore, "featureFlags", "bot");
    const shouldUseBotSnap = await getDoc(shouldUseBotDot);

    if (!shouldUseBotSnap.exists()) {
      return false;
    }

    const botFf = shouldUseBotSnap.data() as IBotFeatureFlag;

    if (botFf.shouldUseBot === true) {
      return true;
    }
    return false;
  }
}
