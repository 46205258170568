export type GovernmentIdType =
  | "Drivers License"
  | "US Passport"
  | "Foreign Passport"
  | "State/Local/Tribe issued ID"
  | "Tribal ID"
  | "Other";

export type ReportType =
  | "Handoff"
  | "Exemption Certificate"
  | "BOIR - Initial"
  | "BOIR - Update"
  | "BOIR - Correction";

export type CoverLetterType = "Handoff" | "SelfBill" | "Direct";

export type TaxIdType =
  | "EIN"
  | "SSN/ITIN"
  | "Foreign"

export type TaxIdPlaceholderRelationType = {
  [key in TaxIdType | "None"]: string;
}

export type TaxIdInputMaskRelationType = {
  [key in TaxIdType | "None"]: {
    mask: string;
    definitions?: {
      "#": RegExp;
    };
  };
}
