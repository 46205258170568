<mat-card
  *ngFor="let infoBubble of infoBubbles$ | async"
  class="info-bubble-card"
  [class]="infoBubble.displayType"
>
  <mat-card-header>
    <h2>{{ infoBubble.title }}</h2>
  </mat-card-header>
  <mat-card-content>
    <p>{{ infoBubble.description }}</p>
  </mat-card-content>
  <mat-card-actions>
    <button
      mat-button
      mat-raised-button
      (click)="goToHelpDesk(infoBubble.buttonLink)"
    >
      {{ infoBubble.buttonText }}
    </button>
  </mat-card-actions>
</mat-card>
