import { Component, inject, Input, OnDestroy, OnInit } from "@angular/core";
import { collection } from "firebase/firestore";
import { BehaviorSubject, Observable } from "rxjs";
import { BaseComponent } from "../base/base.component";
import {
  collectionData,
  Firestore,
  query,
  where,
} from "@angular/fire/firestore";

type DisplayType = "info" | "warning" | "error";
type BubbleLocation = "nav" | "manage" | "modal";

interface IInfoBubble {
  title: string;
  description: string;
  buttonLink: string;
  buttonText: string;
  isDisplayed: boolean;
  displayType: DisplayType;
  bubbleLocation: BubbleLocation;
}

@Component({
  selector: "app-info-bubble",
  templateUrl: "./info-bubble.component.html",
  styleUrls: ["./info-bubble.component.scss"],
})
export class InfoBubbleComponent
  extends BaseComponent
  implements OnInit, OnDestroy
{
  @Input() bubbleLocation: BubbleLocation = "nav";
  private firestore: Firestore = inject(Firestore);

  infoBubblesSource$ = new BehaviorSubject<IInfoBubble[]>([]);
  infoBubbles$ = this.infoBubblesSource$.asObservable();

  constructor() {
    super();
  }

  ngOnInit() {
    this.fetchInfoBubbleFromFirestore();
  }

  override ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  private fetchInfoBubbleFromFirestore() {
    const infoBubbleRef = query(
      collection(this.firestore, "infoBubble"),
      where("isDisplayed", "==", true),
      where("bubbleLocation", "==", this.bubbleLocation)
    );

    this.infoBubbles$ = collectionData(infoBubbleRef) as Observable<
      IInfoBubble[]
    >;
  }

  goToHelpDesk(url: string) {
    window.open(url, "_blank");
  }
}
