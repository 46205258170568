import { Component, Inject, OnInit, inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialog } from "@angular/material/dialog";
import { MatTableDataSource } from "@angular/material/table";
import {
  PreviewDocumentDialogComponent,
  PreviewDocumentDialogInput,
} from "src/app/core/components/preview-document-dialog/preview-document-dialog.component";
import {
  IEntityDetails,
  IFiling,
  IEntity,
  SubmissionStatuses,
  IEntityUpdates,
} from "src/app/core/interfaces/entity.interface";
import { BoirFilingsService } from "src/app/core/services/boir-filings.service";
import { PdfService } from "src/app/core/services/pdf.service";
import { StatusDialogComponent } from "../status-dialog/status-dialog.component";
import { Buffer } from "buffer";
import { EntityDetails } from "src/app/state/entity-details-state/entity-details.actions";
import { Store } from "@ngxs/store";
import * as Sentry from "@sentry/angular-ivy";
import { TranscriptErrorDialogComponent } from "../transcript-error-dialog/transcript-error-dialog.component";
import { collection, Firestore, getDocs, query, where } from "@angular/fire/firestore";

@Component({
  selector: "app-boi-filings-dialog",
  templateUrl: "./boi-filings-dialog.component.html",
  styleUrls: ["./boi-filings-dialog.component.scss"],
})
export class BoiFilingsDialogComponent implements OnInit {
  private firestore: Firestore = inject(Firestore);
  private boirFilingsService = inject(BoirFilingsService);
  private dialog = inject(MatDialog);
  protected transcriptionIsLoadingMap: { [processId: string]: boolean } = {};

  dataSource = new MatTableDataSource<IFiling>([]);

  displayedColumns: string[] = [
    "date",
    "reportType",
    "view",
    "status",
    "transcript",
  ];

  public pdf = inject(PdfService);
  private store = inject(Store);

  public currentViewedEntityIndex = 0;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      entityDetails: IEntityDetails;
      entityId: string;
    }
  ) { }

  get sortedFilings() {
    return Object.values(this.data.entityDetails?.filings).sort(
      (a, b) => b.snapshotDate.getTime() - a.snapshotDate.getTime()
    );
  }

  ngOnInit() {
    this.dataSource.data = this.sortedFilings;
    // render first pdf in list
    if (this.sortedFilings.length > 0) {
      this.renderPdf(this.sortedFilings[0].entitySnapshot, 0);
    }
  }

  renderPdf(entitySnapshot: IEntity, index: number, event?: Event) {
    event?.preventDefault();
    this.pdf.generateReport(entitySnapshot);
    this.currentViewedEntityIndex = index;
  }

  async checkSubmissionStatus(filing: IFiling) {
    console.log("checkSubmissionStatus", filing);
    if (!filing.processId) return;

    const isInWebQueue = await this.isInWebQueue(filing?.entitySnapshot?.id);

    if (isInWebQueue) {
      this.dialog.open(StatusDialogComponent, {
        width: "800px",
        data: {
          status: {
            submissionStatus: filing?.submissionStatus,
            fincenID: filing?.entitySnapshot?.fincenId,
          },
        },
      });
      return;
    }

    const status = await this.boirFilingsService.checkSubmissionStatus(
      filing.processId
    );

    if (status.submissionStatus !== "submission_accepted") {
      Sentry.captureException(status);
    }

    filing.submissionStatus = status.submissionStatus;

    this.updateEntityDetails(filing);
    this.updateEntity(status.submissionStatus, status.fincenID);

    this.dialog.open(StatusDialogComponent, {
      width: "800px",
      data: {
        status
      },
    });
  }

  private async isInWebQueue(entityId: string) {
    const queueItem = query(collection(this.firestore, "webBoirFilingQueue"),
      where("entityId", "==", entityId));

    const queueSnapshot = await getDocs(queueItem);
    if (queueSnapshot.size > 0) {
      return true;
    }
    return false;
  }

  async getTranscript(processId: string, entityId: string) {
    try {
      this.transcriptionIsLoadingMap[processId] = true;
      const transcript = await this.boirFilingsService.getTranscript(
        processId,
        entityId
      );
      this.transcriptionIsLoadingMap[processId] = false;

      if (!transcript?.pdfBinary) {
        throw new Error("Failed to retrieve transcript")
      }

      const { pdfBinary } = transcript;

      const pdf = Buffer.from(pdfBinary, "base64");

      this.dialog.open(PreviewDocumentDialogComponent, {
        data: <PreviewDocumentDialogInput>{
          base64Image: {
            mediaType: "application/pdf",
            image: pdf.toString("base64"),
          },
          idUrl: "test",
        },
      });
    } catch (e) {
      this.transcriptionIsLoadingMap[processId] = false;
      this.dialog.open(TranscriptErrorDialogComponent, {
        width: "860px",
      });
      console.error(e);
    }
  }

  private async updateEntity(status: SubmissionStatuses, fincenId?: string) {
    let entityUpdates: IEntityUpdates = {
      status: this.boirFilingsService.apiStatusToSubmissionStatus(status),
      fincenStatus: status,
    };
    if (fincenId) {
      entityUpdates = {
        ...entityUpdates,
        fincenId,
      };
    }
    await this.boirFilingsService.updateEntity(this.data.entityId, entityUpdates);
  }

  private async updateEntityDetails(newFiling: IFiling) {
    this.store.dispatch(
      new EntityDetails.Set(this.data.entityId, {
        ...this.data.entityDetails,
        filings: this.data.entityDetails.filings.map((filing) =>
          filing.processId === newFiling.processId ? newFiling : filing
        ),
      })
    );
  }
}
