import { inject, Injectable } from '@angular/core';
import { Functions } from '@angular/fire/functions';
import { httpsCallable } from 'firebase/functions';

@Injectable({
  providedIn: 'root'
})
export class AlertsService {
  private functions = inject(Functions);

  async sendEmailAlert(message: string) {
    try {
      const sendEmailAlertCallable = httpsCallable<{ message: string }, void>(
        this.functions,
        'sendEmailAlert'
      );

      sendEmailAlertCallable({ message });
    } catch (error) {
      console.error('Error sending email alert', error);
    }
  }
}
