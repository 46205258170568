import { inject, Injectable } from "@angular/core";
import { Auth } from "@angular/fire/auth";
import { collection, Firestore, getDoc, getDocs, query, where, } from "@angular/fire/firestore";
import { Functions, httpsCallable } from "@angular/fire/functions";
import { MatSnackBar } from "@angular/material/snack-bar";
import { ActivatedRoute, Router } from "@angular/router";
import { Action, State, StateContext, Store } from "@ngxs/store";
import { doc, writeBatch } from "firebase/firestore";
import {
  IEntity,
  IEntityDetails,
  IHttpsCallableResponse,
  IMirrorOwnerRequest,
  IUpsertFileEntityRequest,
} from "src/app/core/interfaces";
import { BoirFilingsService } from "src/app/core/services/boir-filings.service";
import { ErrorHandlerService } from "src/app/core/services/firebase-error-handler.service";
import { convertDatesToTimestamps, convertTimestampsToDates, } from "src/app/core/utils/helper-functions";
import { EntityDetailsStateModel } from "../entity-details-state/entity-details-model.interface";
import { EntityDetails } from "../entity-details-state/entity-details.actions";
import { initialState, SecureFileStateModel, } from "./secure-file-model.interface";
import { SecureFile } from "./secure-file.actions";
import { collectionChanges } from "rxfire/firestore";
import { debounceTime, skip, Subscription } from "rxjs";
import { FeatureFlagsService } from "src/app/core/services/feature-flags.service";

@State<SecureFileStateModel>({
  name: "secureFile",
  defaults: { ...initialState },
})
@Injectable()
export class SecureFileState {
  private firestore: Firestore = inject(Firestore);
  private auth: Auth = inject(Auth);
  private functions = inject(Functions);
  private snackbar: MatSnackBar = inject(MatSnackBar);
  private errorHandler = inject(ErrorHandlerService);
  private store = inject(Store);
  private boirFilingsService = inject(BoirFilingsService);
  private router: Router = inject(Router);
  private activatedRoute: ActivatedRoute = inject(ActivatedRoute);
  private featureFlags = inject(FeatureFlagsService);

  private entitiesCollectionSubscription!: Subscription;

  @Action(SecureFile.Reset)
  reset(ctx: StateContext<SecureFileStateModel>) {
    ctx.setState({ ...initialState });
  }

  @Action(SecureFile.GetEntity)
  async getEntity(
    ctx: StateContext<SecureFileStateModel>,
    action: SecureFile.GetEntity
  ) {
    try {
      const { entityId } = action;
      const entityCollection = collection(this.firestore, "secureFileEntities");
      const entityDoc = doc(entityCollection, entityId);
      const entitySnapshot = await getDoc(entityDoc);

      const entity = entitySnapshot.data() as IEntity | undefined;
      if (!entity) {
        throw new Error("File Entity does not exist!");
      }

      const entityWithDates = convertTimestampsToDates(entity);

      ctx.dispatch(new SecureFile.GetEntitySuccess(entityWithDates));
    } catch (error) {
      this.errorHandler.handleError(error);
    }
  }

  @Action(SecureFile.GetEntitySuccess)
  getEntitySuccess(
    ctx: StateContext<SecureFileStateModel>,
    action: SecureFile.GetEntitySuccess
  ) {
    const { entity } = action;
    const existingEntities = ctx.getState().entities;
    ctx.patchState({
      entities: [
        ...existingEntities.filter(
          (existingEntity) => existingEntity.id !== entity.id
        ),
        entity,
      ],
    });
  }

  @Action(SecureFile.GetEntities)
  async getEntities(ctx: StateContext<SecureFileStateModel>) {
    const userId = this.auth.currentUser?.uid;
    if (!userId) {
      throw new Error("User is not authenticated");
    }

    const entityCollection = collection(this.firestore, "secureFileEntities");
    const entityQuery = query(
      entityCollection,
      where("userIds", "array-contains", userId)
    );
    const entitySnapshot = await getDocs(entityQuery);

    let entities = entitySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    })) as IEntity[];

    entities = convertTimestampsToDates(entities);

    ctx.patchState({ entities });

    if (!this.entitiesCollectionSubscription) {
      this.entitiesCollectionSubscription = collectionChanges(
        entityQuery,
        { events: ['added', 'removed'] }
      ).pipe(
        debounceTime(1000),
        skip(1)
      ).subscribe(() => {
        ctx.dispatch(new SecureFile.GetEntities());
      })
    }
  }

  @Action(SecureFile.UpsertEntity)
  async upsertEntity(
    ctx: StateContext<SecureFileStateModel>,
    action: SecureFile.UpsertEntity
  ) {
    const { entityId, entity, updatedOwnerIds } = action;

    const upsertEntityCallable = httpsCallable<
      IUpsertFileEntityRequest,
      IHttpsCallableResponse
    >(this.functions, "upsertFileEntity");

    const entityWithTimestamps = convertDatesToTimestamps(entity) as IEntity;

    const response = await upsertEntityCallable({
      entityId,
      entity: entityWithTimestamps,
      updatedOwnerIds,
    });

    if (!response.data.success) {
      this.snackbar.open(response.data.message, "Dismiss", {
        duration: 3000,
      });
      return;
    }

    ctx.dispatch(new SecureFile.UpsertEntitySuccess(entity));
  }

  @Action(SecureFile.UpsertEntitySuccess)
  upsertEntitySuccess(
    ctx: StateContext<SecureFileStateModel>,
    action: SecureFile.UpsertEntitySuccess
  ) {
    const { entity } = action;
    ctx.patchState({
      entities: ctx
        .getState()
        .entities.map((existingEntity) =>
          existingEntity.id === entity.id ? entity : existingEntity
        ),
    });
  }

  @Action(SecureFile.SetEntity)
  setEntity(
    ctx: StateContext<SecureFileStateModel>,
    action: SecureFile.SetEntity
  ) {
    try {
      const { entityId, entity } = action;
      const existingEntities = ctx.getState().entities;

      const updatedEntity = { ...entity, id: entityId };

      const entityExists = existingEntities.some(
        (existingEntity) => existingEntity.id === entityId
      );

      ctx.patchState({
        entities: entityExists
          ? // Update existing entity
          existingEntities.map((existingEntity) =>
            existingEntity.id === entityId ? updatedEntity : existingEntity
          )
          : // Add new entity
          [...existingEntities, updatedEntity],
      });
    } catch (error) {
      this.errorHandler.handleError(error);
    }
  }

  @Action(SecureFile.SignAndSubmit)
  async signAndSubmit(
    ctx: StateContext<SecureFileStateModel>,
    action: SecureFile.SignAndSubmit
  ) {
    const { entityId, signerInfo } = action;
    const entity = ctx
      .getState()
      .entities.find((entity) => entity.id === entityId);
    if (!entity) {
      this.snackbar.open("Entity not found", "Dismiss", {
        duration: 3000,
      });
      return;
    }

    const shouldUseBot = await this.featureFlags.shouldUseBot();

    if (shouldUseBot) {
      const enqueueResponse = await this.boirFilingsService.enqueueWebBOIRFiling(entity.id);

      if (!enqueueResponse) {
        return;
      }
      // Update entity consent data
      ctx.dispatch(new SecureFile.UpsertEntity(
        entityId,
        {
          ...entity,
          status: "e-Filing Pending",
          consentData: signerInfo,
        },
        []
      )).subscribe(() => {
        const params = { ...this.activatedRoute?.snapshot?.queryParams };
        this.router.navigate(["payments/thank-you"], { queryParams: params });
        ctx.dispatch(new SecureFile.GetEntities());
      });
    } else {
      this.boirFilingsService
        .enqueueBOIR(entity, signerInfo, null)
        .then((res) => {
          if (!res) return;
          const params = { ...this.activatedRoute?.snapshot?.queryParams };
          this.router.navigate(["payments/thank-you"], { queryParams: params });
          ctx.dispatch(new SecureFile.GetEntities());
        });
    }
  }

  @Action(SecureFile.DeleteEntity)
  async deleteEntity(
    ctx: StateContext<SecureFileStateModel>,
    action: SecureFile.DeleteEntity
  ) {
    try {
      const { entityId } = action;

      const entity = ctx
        .getState()
        .entities.find((entity) => entity.id === entityId);

      const batch = writeBatch(this.firestore);

      // Delete the entity
      const entityDoc = doc(this.firestore, "secureFileEntities", entityId);
      batch.delete(entityDoc);

      const entityDetails = this.store.selectSnapshot<EntityDetailsStateModel>(
        (state) => state.entityDetails
      );

      if (
        (entity && entity.handedOff && entityDetails.userIds.length > 0) ||
        entityDetails.orgId
      ) {
        const updatedEntityDetails: IEntityDetails = {
          ...entityDetails,
          jwtToken: "",
          secureFileTransferComplete: false,
          userIds: [
            ...entityDetails.userIds.filter(
              (id) => id !== this.auth.currentUser?.uid
            ),
          ],
        };
        ctx.dispatch(new EntityDetails.Set(entityId, updatedEntityDetails));
      } else {
        // Delete the entity details
        const entityDetailsDoc = doc(this.firestore, "entityDetails", entityId);
        batch.delete(entityDetailsDoc);
      }

      await batch.commit();

      ctx.dispatch(new SecureFile.DeleteEntitySuccess());
    } catch (error) {
      this.errorHandler.handleError(error);
    }
  }

  @Action(SecureFile.DeleteOwner)
  async deleteOwner(
    ctx: StateContext<SecureFileStateModel>,
    action: SecureFile.DeleteOwner
  ) {
    const { entity, ownerId, orgId } = action;
    if (entity.handedOff) {
      if (!orgId) {
        return;
      }
      const mirrorOwnerDeletionCallable = httpsCallable<
        IMirrorOwnerRequest,
        IHttpsCallableResponse
      >(this.functions, "mirrorOwnerDeletion");

      const mirrorOwnerResponse = await mirrorOwnerDeletionCallable({
        orgId,
        ownerId,
        entityId: entity.id,
      });

      if (!mirrorOwnerResponse.data.success) {
        throw new Error(mirrorOwnerResponse.data.message);
      }
    } else {
      delete entity.owners[ownerId];
      delete entity.ownerRelationships[ownerId];
      ctx.dispatch(new SecureFile.UpsertEntity(entity.id, entity, []));
    }

    ctx.dispatch(new SecureFile.GetEntities());
  }
  @Action(SecureFile.DeleteEntitySuccess)
  async deleteEntitySuccess(ctx: StateContext<SecureFileStateModel>) {
    try {
      const userId = this.auth.currentUser?.uid;
      if (!userId) {
        throw new Error("User does not exist!");
      }
      ctx.dispatch(new SecureFile.GetEntities());
      this.snackbar.open("Entity deleted successfully!", "Dismiss", {
        duration: 5000,
      });
    } catch (error) {
      this.errorHandler.handleError(error);
    }
  }
}
